<template>
  <MainApp>
    <a-layout>
      <a-layout-content :class="$style.centered">
        <slot/>
        <CommonFooterLinks/>
      </a-layout-content>
    </a-layout>
  </MainApp>
</template>
<script lang="ts" setup>

useHead(defaultHead());
</script>
<style lang="scss" module>
.centered {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 12px;
  gap: 16px;
}
</style>
